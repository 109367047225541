import { BaSeTheme } from '../../theme';
import { ThemeNamedColorOrColorValue } from '../../theme/theme-interface';
import {
  createColor,
  createColorSmartLightingControl,
} from './calculate-pattern';
import { Color } from './color';

const inst = BaSeTheme.colors.institucionais;
const feed = BaSeTheme.colors.feedbackInterface;

export interface TransformColors {
  darkHoverColor: string;
  lightFocusColor: string;
  lightHoverColor: string;
  lightFocusColorTransparent: string;
  lightHoverColorTransparent: string;
}

const reduceLevel = (level: number) => {
  if (level > 1 && level <= 100) {
    level /= 100;
  }
  return level;
};

const memo: { [color: string]: TransformColors } = {};

export const ColorMapper = {
  default: {
    foreground: inst.azulSebrae36,
    hover: {
      defaultDark: inst.azulSebrae30,
      defaultLight: inst.azulSebrae97,
      transparentLight: createColor(inst.azulSebrae97, {
        l: reduceLevel(65),
        opacity: reduceLevel(14),
      }),
    },
    focus: {
      defaultLight: inst.azulSebrae75,
      transparentLight: createColor(inst.azulSebrae75, {
        l: reduceLevel(70),
        opacity: reduceLevel(83),
      }),
    },
    unchecked: inst.azulSebrae60,
    getColorByNumber: (level: number) =>
      createColor(inst.azulSebrae36, { l: reduceLevel(level) }),
    getColorByNumberOpacity: (level: number, opacity: number) =>
      createColor(inst.azulSebrae36, {
        l: reduceLevel(level),
        opacity: reduceLevel(opacity),
      }),
  },
  grayScale: {
    foreground: inst.cinzaSebrae45,
    hover: {
      defaultDark: inst.cinzaSebrae30,
      defaultLight: inst.cinzaSebrae97,
    },
    focus: {
      defaultLight: inst.cinzaSebrae75,
      transparentLight: '#A8B3BDD4',
    },
    getColorByNumber: (level: number) =>
      createColor(inst.cinzaSebrae45, { l: reduceLevel(level) }),
    getColorByNumberOpacity: (level: number, opacity: number) =>
      createColor(inst.cinzaSebrae45, {
        l: reduceLevel(level),
        opacity: reduceLevel(opacity),
      }),
  },
  confirmation: {
    foreground: feed.sucesso26,
    hover: {
      defaultDark: feed.sucesso20,
      defaultLight: feed.sucesso95,
      transparentLight: createColor(feed.sucesso95, {
        l: reduceLevel(65),
        opacity: reduceLevel(14),
      }),
    },
    focus: {
      defaultLight: feed.sucesso75,
      transparentLight: createColor(feed.sucesso75, {
        l: reduceLevel(70),
        opacity: reduceLevel(83),
      }),
    },
    unchecked: createColor(feed.sucesso26, { l: 0.6 }),
    getColorByNumber: (level: number) =>
      createColor(feed.sucesso26, { l: reduceLevel(level) }),
    getColorByNumberOpacity: (level: number, opacity: number) =>
      createColor(feed.sucesso26, {
        l: reduceLevel(level),
        opacity: reduceLevel(opacity),
      }),
  },
  destructive: {
    foreground: feed.erro45,
    hover: {
      defaultDark: feed.erro35,
      defaultLight: feed.erro95,
      transparentLight: createColor(feed.erro95, {
        l: reduceLevel(65),
        opacity: reduceLevel(14),
      }),
    },
    focus: {
      defaultLight: feed.erro75,
      transparentLight: createColor(feed.erro75, {
        l: reduceLevel(70),
        opacity: reduceLevel(83),
      }),
    },
    unchecked: createColor(feed.erro45, { l: 0.6 }),
    getColorByNumber: (level: number) =>
      createColor(feed.erro45, { l: reduceLevel(level) }),
    getColorByNumberOpacity: (level: number, opacity: number) =>
      createColor(feed.erro45, {
        l: reduceLevel(level),
        opacity: reduceLevel(opacity),
      }),
  },
  attention: {
    foreground: feed.atencao55,
    hover: {
      defaultDark: feed.atencao35,
      defaultLight: feed.atencao95,
      transparentLight: createColor(feed.atencao95, {
        l: reduceLevel(65),
        opacity: reduceLevel(14),
      }),
    },
    focus: {
      defaultLight: feed.atencao75,
      transparentLight: createColor(feed.atencao75, {
        l: reduceLevel(70),
        opacity: reduceLevel(83),
      }),
    },
    unchecked: createColor(feed.atencao55, { l: 0.6 }),
    getColorByNumber: (level: number) =>
      createColor(feed.atencao55, { l: reduceLevel(level) }),
    getColorByNumberOpacity: (level: number, opacity: number) =>
      createColor(feed.atencao55, {
        l: reduceLevel(level),
        opacity: reduceLevel(opacity),
      }),
  },
  disabled: {
    foreground: inst.cinzaSebrae75,
    background: inst.cinzaSebrae90,
    lighterBackground: inst.cinzaSebrae97,
    bold: inst.cinzaSebrae30,
    getColorByNumber: (level: number) =>
      createColor(inst.cinzaSebrae75, { l: reduceLevel(level) }),
    getColorByNumberOpacity: (level: number, opacity: number) =>
      createColor(inst.cinzaSebrae75, {
        l: reduceLevel(level),
        opacity: reduceLevel(opacity),
      }),
  },
  light: {
    focus: {
      create: (color: string) =>
        createColorSmartLightingControl(color, { l: 0.75 }, 'lighter'),
      createTransparent: (color: string) =>
        createColorSmartLightingControl(
          color,
          { l: 0.7, opacity: 0.83 },
          'lighter',
        ),
    },
    hover: {
      create: (color: string) =>
        createColorSmartLightingControl(color, { l: 0.95 }, 'lighter'),
      createTransparent: (color: string) =>
        createColorSmartLightingControl(
          color,
          { l: 0.6, opacity: 0.14 },
          'lighter',
        ),
    },
  },
  dark: {
    hover: {
      create: (color: string) =>
        createColorSmartLightingControl(color, { l: 0.3 }, 'darker'),
    },
  },
  create: (color: string, lightLevel: number) => {
    if (lightLevel > 1 && lightLevel <= 100) {
      lightLevel /= 100;
    }
    return createColor(color, { l: lightLevel });
  },
  createTransparent: (color: string, lightLevel: number, opacity: number) => {
    if (lightLevel > 1 && lightLevel <= 100) {
      lightLevel /= 100;
    }
    if (opacity > 1 && opacity <= 100) {
      opacity /= 100;
    }
    return createColor(color, { l: lightLevel, opacity });
  },
  createMostUsedColors: (color: string): TransformColors => {
    if (memo[color]) {
      return memo[color];
    }
    const darkHoverColor = createColorSmartLightingControl(
      color,
      { l: 0.3 },
      'darker',
    );
    const lightHoverColor = createColorSmartLightingControl(
      color,
      { l: 0.95 },
      'lighter',
    );
    const lightFocusColor = createColorSmartLightingControl(
      color,
      { l: 0.75 },
      'lighter',
    );
    const lightHoverColorTransparent = createColorSmartLightingControl(
      color,
      { l: 0.6, opacity: 0.14 },
      'lighter',
    );
    const lightFocusColorTransparent = createColorSmartLightingControl(
      color,
      { l: 0.7, opacity: 0.83 },
      'lighter',
    );

    memo[color] = {
      darkHoverColor,
      lightFocusColor,
      lightHoverColor,
      lightFocusColorTransparent,
      lightHoverColorTransparent,
    };
    return memo[color];
  },
  typography: {
    default: inst.cinzaSebrae,
    strong: inst.cinzaSebrae30,
    soft: inst.cinzaSebrae45,
    unselected: inst.cinzaSebrae60,
    selected: inst.cinzaSebrae20,
  },
  isValidType: (color: ThemeNamedColorOrColorValue) =>
    color === 'default' ||
    color === 'confirmation' ||
    color === 'attention' ||
    color === 'destructive',
  isValidColor: (color: string) => Color.validateColor(color),
  getColor: (color?: string, defaultReturn?: string): string => {
    const stringReturn = defaultReturn ?? '';
    if (!color) {
      return stringReturn;
    }
    if (Color.validateColor(color)) {
      return color;
    }
    if (ColorMapper.isValidType(color)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return ColorMapper[color]?.foreground as string;
    }
    return stringReturn;
  },
};

export type ColorMapperType = (typeof ColorMapper)[keyof typeof ColorMapper];
