import { Color } from './color';

const lightLevelHover = 0.65;
const lightLevelFocus = 0.7;
const opacityLevelHover = 0.14;
const opacityLevelFocus = 0.83;

interface HSLModeProps {
  h?: number;
  s?: number;
  l?: number;
  opacity?: number;
}

export const getHSLValues = (color: Color | string | null) => {
  if (!color) {
    return null;
  }
  if (typeof color === 'string') {
    const newColor = Color.generate(color);
    if (newColor) {
      return newColor.hsl();
    }
    return null;
  }
  return color?.hsl();
};

export const getLValue = (color: string) => {
  const localColor = Color.generate(color);
  const hslColor = getHSLValues(localColor);

  return hslColor?.l;
};

export const hueMapperHover = (color: Color | null) => {
  if (!color) {
    return;
  }
  let { h, s } = color.hsl();
  h /= 100;
  s /= 100;
  const { r, g, b } = color.HSVtoRGB(h, s, lightLevelHover);
  return new Color(r, g, b).toStringWithOpacity(opacityLevelHover);
};

export const hueMapperFocus = (color: Color | null) => {
  if (!color) {
    return;
  }
  let { h, s } = color.hsl();
  h /= 100;
  s /= 100;
  const { r, g, b } = color.HSVtoRGB(h, s, lightLevelFocus);
  return new Color(r, g, b).toStringWithOpacity(opacityLevelFocus);
};

export const createButtonHoverColor = (color: string) => {
  const baseColorObj = Color.generate(color);
  if (baseColorObj?.isGrayScale()) {
    return baseColorObj.toStringWithOpacity(0.14);
  }
  return hueMapperHover(baseColorObj);
};

export const createButtonFocusColor = (color: string) => {
  const baseColorObj = Color.generate(color);
  if (baseColorObj?.isGrayScale()) {
    return baseColorObj.toStringWithOpacity(0.83);
  }
  return hueMapperFocus(baseColorObj);
};

export const createButtonDarkTextColor = (color: string) => {
  const baseColorObj = Color.generate(color);
  if (!baseColorObj) {
    return;
  }
  const { h, l, s } = baseColorObj.hsl();
  let newL = l / 100;
  const reduceValue = 0.1;
  newL = Math.max(0, newL - reduceValue);
  const { r, g, b } = baseColorObj.HSVtoRGB(h / 100, s / 100, newL);
  return new Color(r, g, b).toString();
};

export const createColor = (color: string, colorConfig: HSLModeProps) => {
  if (!color) {
    return '#000';
  }
  if (!colorConfig) {
    return color;
  }

  try {
    const colorClass = Color.generate(color);
    if (!colorClass) {
      return '#000';
    }
    const { h: ccH, s: ccS, l: ccL } = colorClass.hsl();
    const { h, s, l, opacity } = colorConfig;
    const { r, g, b } = colorClass.HSVtoRGB(
      h ?? ccH / 100,
      s ?? ccS / 100,
      l ?? ccL / 100,
    );
    const newColorClass = new Color(r, g, b, opacity);
    return newColorClass.toString();
  } catch (e) {
    return '#000';
  }
};

export type LightLevel = 'darker' | 'lighter';

export const createColorSmartLightingControl = (
  color: string,
  colorConfig: HSLModeProps,
  lightLevel?: LightLevel | number,
) => {
  const gapThreshold = 0.05;
  if (Color.validateColor(color) && colorConfig) {
    const colorClass = Color.generate(color);
    if (!colorClass) {
      return '#000';
    }
    const { l: hslLight } = colorClass.hsl();
    const light =
      typeof lightLevel === 'string' ? hslLight : lightLevel ?? hslLight;
    const { l } = colorConfig;
    if (l && lightLevel) {
      if (lightLevel === 'darker' && light / 100 < l + gapThreshold) {
        return createColor(color, { ...colorConfig, l: light / 200 });
      }
      if (lightLevel === 'lighter' && light / 100 > l - gapThreshold) {
        const newLight = light / 100;
        return createColor(color, {
          ...colorConfig,
          l: newLight + (1 - newLight) / 2,
        });
      }
      if (lightLevel && light / 100 > l - gapThreshold) {
        const newLight = light / 100;
        return createColor(color, {
          ...colorConfig,
          l: newLight + (1 - newLight) / 2,
        });
      }
      return createColor(color, colorConfig);
    }
  }
  return createColor(color, colorConfig);
};
